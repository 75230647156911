import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../scss/app.scss'
import drawer from './Drawer'
import './jump.setting'
import './scrollmagic.setting'
import './swiper.setting'
import './common'

document.addEventListener('DOMContentLoaded', () => {
  drawer.init()
})
