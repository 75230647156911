import ScrollMagic from 'scrollmagic'

document.addEventListener('DOMContentLoaded', () => {
  const pageupController = new ScrollMagic.Controller()
  new ScrollMagic.Scene({
    triggerElement: 'body',
    triggerHook: 0,
    offset: 200,
  })
    .setClassToggle('body', '-visible-pageup')
    .addTo(pageupController)

  new ScrollMagic.Scene({
    triggerElement: '.l-footer',
    triggerHook: 1,
  })
    .setClassToggle('#js-pageup', '-static')
    .addTo(pageupController)

  const topScrollController = new ScrollMagic.Controller()
  new ScrollMagic.Scene({
    triggerElement: '#Vision',
    triggerHook: 0.5,
  })
    .on('enter', () => {
      document.body.dataset.currentSection = 'vision'
    })
    .on('leave', () => {
      document.body.dataset.currentSection = 'kv'
    })
    .addTo(topScrollController)
  new ScrollMagic.Scene({
    triggerElement: '#News',
    triggerHook: 0.5,
  })
    .on('enter', () => {
      document.body.dataset.currentSection = 'news'
    })
    .on('leave', () => {
      document.body.dataset.currentSection = 'vision'
    })
    .addTo(topScrollController)
})
