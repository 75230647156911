import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'

window.addEventListener('load', function() {
  const swiper = new Swiper('.c-mainvis', {
    direction: 'horizontal',
    effect: 'slide',
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  const playBtn: HTMLAnchorElement | null = document.querySelector(
    '#js-mainvis__play-btn'
  )

  if (playBtn) {
    playBtn.addEventListener('click', () => {
      if (swiper.autoplay) {
        if (swiper.autoplay.running) {
          swiper.autoplay.stop()
          playBtn.classList.add('-pause')
        } else {
          swiper.autoplay.start()
          playBtn.classList.remove('-pause')
        }
      }
    })
  }
})
